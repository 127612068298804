.select-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.select-input > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.select-control > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.lg-select-input-group {
  width: 300px;
  max-width: 300px;
}

.md-select-input-group {
  width: 170px;
  max-width: 170px;
}

.lg-label-input-group {
  width: 300px;
  max-width: 300px;
}

.md-label-input-group {
  width: 170px;
  max-width: 170px;
}

.lg-text-input-group {
  width: 300px;
  max-width: 300px;
  text-align: center;
}

.md-text-input-group {
  width: 170px;
  max-width: 170px;
  text-align: center;
}

.sm-text-input-group {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.switch-input-group {
  display: flex;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 0.25rem;
  color: #768192;
  width: 80px;
  border-color: #d8dbe0;
}

.switch-input {
  display: flex;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  color: #768192;
  border-color: #d8dbe0;
}

.c-switch-label .c-switch-slider::before,
.c-switch-label .c-switch-slider::after {
  z-index: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.blured {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}


.modal-body {
  overflow-y: auto !important;
  max-height: calc(100vh - 200px) !important;
}

.btn-info {
  background: #033B7C !important;
}



